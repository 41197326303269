.am-list {
  margin-left: -24px;
  margin-right: -24px;
}

.am-list-item {
  padding: 0 24px;
}

.btnShowSearch {
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0,0,0,.6);
}
.SlickCarousel {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #f4f4f4;

  .slick-slide img {
    display: block;
    width: 100%;
  }

  .slickDots {
    position: absolute;
    bottom: 15px;
    text-align: center;
    width: 100%;
    padding-inline-start: 0 !important;
    margin: 0;

    > li {
      display: inline-block;
      margin: 0 5px;
    }
    .paginationBullet {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: #000;
      opacity: 0.4;
    }
    .slick-active .paginationBullet {
      background-color: #ed1c24;
      opacity: 0.6;
    }
  }
}